<template>
  <div v-if="show" id="congrats" class="congrats">
    <lottie-player src="https://lottie.host/e384e6a3-5ef2-42a6-968b-6e7cc2e52a2a/shdRFRbZKg.json" 
    background="transparent" speed="1" style="position: absolute;top: 0;width: 100%; height: 100%;" 
    loop autoplay></lottie-player>
    
    <lottie-player src="https://lottie.host/131c39d4-0b37-4f5c-8891-bad820c879aa/5fPSqIw3He.json" 
    background="transparent" speed="2" style="position: absolute;top: 30%;width: 100%; height: 80%;" 
    loop autoplay></lottie-player>
  </div>
</template>

<script>
export default {
    name: 'CongratsVue',
    data(){
      return{
          birthday: '1996-01-01', //yyyy-mm-dd
          show: false,
      }
    },
    mounted(){
        const birth = new Date(this.birthday);
        const currentDate = new Date();
        if(birth.getDate() === currentDate.getDate() 
          && birth.getMonth === currentDate.getMonth)
        {
          this.show = true;

          setTimeout(() => {
              document.getElementById("congrats").remove();
          }, 3300);
        }
    },
}
</script>

<style>

</style>