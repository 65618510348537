<template>
    <div class="request-modal">
        <div class="overlayer" @click="closeRequest"></div>
        <div class="card">
            <div class="card-header">
                <span>Request Live Attendance</span>
            </div>
            <div class="card-body">
                <date-picker :date="startTime" :option="option" :limit="limit" v-model="dates"></date-picker>
                <span class="error-message">{{ error_date }}</span>
                <!-- <input type="text" class="input" id="request-date" placeholder="Tanggal" 
                @focus="dateInput" @blur="dateBlur"> -->
                <div class="input-group">
                    <label v-if="description === null" for="desc" class="input-label">Request Reason</label>
                    <select name="desc" id="" v-model="description">
                        <option value="Ganti Jadwal">Ganti Jadwal</option>
                        <option value="Handphone Bermasalah">Handphone Bermasalah</option>
                        <option value="Tidak Bisa Login">Tidak Bisa Login</option>
                        <option value="Aplikasi Bermasalah">Aplikasi Bermasalah</option>
                        <option value="Lupa Clock In / Clock Out">Lupa Clock In / Clock Out</option>
                    </select>
                    <span class="error-message">{{ error_reason }}</span>
                </div>

                
                <div style="width: 50%;height: 150px;background: var(--border);border-radius: 10px;margin-top: 20px;overflow: hidden;">
                    <img v-show="!is_captured" style="width: 100%;height: 100%;" src="/assets/images/user/default.png" alt="">
                    <div v-show="is_captured" class="camera-input" @click="init">
                        <canvas class="image-holder" id="selectedpicture"></canvas>
                        <div class="image-text">
                        <i style="font-size: 19pt;" class="fa-solid fa-camera" :class="{'text-white' : images}"></i>
                        <span :class="{'text-white' : images}">{{ selectimages }}</span>
                        </div>
                    </div>
                </div>

                <div class="attachment-group">
                    <div class="attachment-wrapper">
                        <span class="label">
                            <i class="fa-solid fa-paperclip"></i>
                            Attachment
                        </span>
                        <input type="file" name="upload" id="upload" class="upload-box" 
                        placeholder="Upload File" ref="file" @input="handelUpload"
                        accept="application/pdf,image/png,image/jpg,image/jpeg">
                    </div>
                    <div style="position: relative;width: 70%;height: 40px;overflow: hidden;font-size: 11pt;margin-left: 25px;">
                        <span style="position: absolute;top: 10px">{{ filename }}</span>
                    </div>                    
                </div>
                <span class="error-message">{{ error_attachment }}</span>

                <div class="input-group">
                    <label v-if="clocking === null" for="desc" class="input-label">In / Out</label>
                    <select name="" id="" v-model="clocking">
                        <option value="true">Clock In</option>
                        <option value="false">Clock Out</option>
                    </select>
                    <span class="error-message">{{ error_clock }}</span>
                </div>
                
                <button class="card-button" @click="submitAttend">
                    <span v-if="!is_processing">Request Attendance</span>

                    <div v-if="is_processing" class="spinner center">
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                        <div class="spinner-blade"></div>
                    </div>
                </button>
            </div>
        </div>
        
    </div>

    <div v-if="showCamera" class="camera-container">
      <div class="camera-switch" @click="switchCam"><i class="fa-solid fa-camera-rotate"></i></div>
      <video class="camera" autoplay></video>
      <div class="camera-close" @click="closeCam"><i class="fa-solid fa-rotate-left"></i></div>
      <div @click="capture" class="camera-snap"><i class="fa-solid fa-camera"></i></div>
    </div>
</template>

<script>
import myDatepicker from 'vue-datepicker'
import axios from 'axios'
export default {
    name: 'RequestVue',
    components:{
        'date-picker': myDatepicker
    },
    data(){
        return{
            showCamera: false,
            is_processing: false,
            is_captured: false,
            listDevices: [],
            error_counter: 0,
            error_date: null,
            error_reason: null,
            error_clock: null,
            error_type:null,
            error_attachment: null,
            dates: null,
            description: null,
            clocking: null,
            types: null,
            files: null,
            filename: null,
            images: null,
            location: null,
            currentlat: 0,
            currentlng: 0,
            latitude: 0,
            longitude: 0,
            startTime: {
                time: ''
            },
            endtime: {
                time: ''
            },
            option: {
                type: 'min',
                week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
                month: ['January', 
                        'February', 
                        'March', 
                        'April', 
                        'May', 
                        'June', 
                        'July', 
                        'August', 
                        'September', 
                        'October', 
                        'November', 
                        'December'],
                format: 'YYYY-MM-DD HH:mm',
                placeholder: 'Date',
                inputStyle: {
                    'width': '295px',
                    'display': 'inline-block',
                    'padding': '6px',
                    'line-height': '22px',
                    'font-size': '16px',
                    'border': '2px solid #fff',
                    'box-shadow': '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
                    'border-radius': '10px',
                    'color': '#393939'
                },
                color: {
                    header: '#FF725E',
                    headerText: '#fff',
                },
                buttons: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                },
                overlayOpacity: 0.5, // 0.5 as default
                dismissible: true // as true as default
            },
            limit:[
                // {
                //     type: 'weekday',
                //     available: [1, 2, 3, 4, 5]
                // },
            ]
        }
    },
    created(){
        this.currentDate()
        this.isInRange()
    },
    methods: {
        currentDate(){
            const currentDate = new Date(new Date().toLocaleString('en', {
                hour12: false,
                timeZone: 'Asia/Jakarta',
            }))

            let month = currentDate.getMonth()
            let year = currentDate.getFullYear()
            let date = currentDate.getDate()
            let fmonth, tmonth, tdate, ldate, tyear;

            date -= 2
            fmonth = month + 1
            var last = new Date(year, fmonth, 0);
            ldate = last.getDate()
            tdate = date + 3

            if(tdate > ldate && fmonth >= 12) { 
                tdate = tdate - ldate
                tmonth = fmonth + 1
                tyear = year + 1
            } else if(tdate > ldate && fmonth < 12) {
                tdate = tdate - ldate
                tmonth = fmonth + 1
                tyear = year
            } else {
                tmonth = fmonth
                tyear = year
            }

            if(fmonth <= 9) fmonth = '0' + String(fmonth);
            if(date <= 9) date = '0' + String(date);
            if(tmonth <= 9) tmonth = '0' + String(tmonth);
            if(tdate <= 9) tdate = '0' + String(tdate)

            const props = {
                type: 'fromto',
                from: String(year) + '-' + String(fmonth) + '-' + String(date), //'2023-09-09'
                to: String(tyear) + '-' + String(tmonth) + '-' + String(tdate),
            }
            
            this.limit.push(props)
        },
        handelUpload(e){
            var maximumSize = 1 * 1024 * 1024;
            if(e.target.files[0]) this.images = e.target.files[0];
            if(e.target.files[0] && e.target.files[0].size > maximumSize){
                this.images = null
                this.$emit('message', "Attachment size too large, maximum 1 Mb!");
                this.$emit('response', false)
                this.$emit('clicked', false)
                this.$emit('alert', true)
                return;
            }

            this.filename = this.images.name;
            const lastDot = this.filename.lastIndexOf('.');
            const ext = this.filename.substring(lastDot + 1);            

            var blob = this.images.slice(0, this.images.size, this.images.type); 
            const newFile = new File([blob], 'Attachment-' + this.$store.getters.GET_AUTH_INFO.name + '-' + String(Math.floor(Math.random() * 1839763215)) + String(Date.now()) + '.' + ext, {
                type: this.images.type
            });

            this.files = newFile;
        },        
        async submitAttend(){
            if(this.is_processing) return;
            if(this.startTime.time == ''){
                this.error_counter += 1
                this.error_date = "Request Date field is required"
            }

            if(this.description === null) {
                this.error_counter += 1
                this.error_reason = "Request reason is required"
            } 
            
            if(this.clocking === null) {
                this.error_counter += 1
                this.error_clock = "Clock In / Out is required"
            }                

            if(this.error_counter > 0){
                setTimeout(() => {
                    this.error_attachment = null
                    this.error_date = null
                    this.error_reason = null
                    this.error_clock = null
                    this.error_type = null
                    return;
                }, 3000);
            } 
  
            if(!this.is_captured){
                this.showCamera = true;
                this.init();
                return;
            }

            try {
                this.is_processing = true
                console.log('Submit')
                const dates =  new Date(new Date(this.startTime.time).toLocaleString('en', {
                    hour12: false,
                    timeZone: 'Asia/Jakarta',
                }));
                const getTime = dates.toTimeString()

                const pictures = document.getElementById("selectedpicture");
                const getImg = pictures.toDataURL("image/png");
                var byteString = atob(getImg.split(',')[1]);
                var mimeString = getImg.split(',')[0].split(':')[1].split(';')[0]
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                const fName = 'Attendance-' + this.$store.getters.GET_AUTH_INFO.name + '-' + String(Math.floor(Math.random() * 1839763215)) + String(Date.now()) + '.png';
                var myBlob = new Blob([ab], {type: mimeString});
                const myFile = new File([myBlob], fName, {
                    type: myBlob.type,
                });

                const formData = new FormData();
                formData.append('usernik', this.$store.getters.GET_AUTH_INFO.nik);
                formData.append('description', this.description);
                formData.append('clocking', this.clocking);
                formData.append('files', this.files);
                formData.append('photo', myFile);
                formData.append('attendDate', dates);
                formData.append('attendTime', getTime.split(" ")[0]);
                formData.append('lok_nm', this.location);
                formData.append('latitude', this.latitude);
                formData.append('longitude', this.longitude);

                for (var pair of formData.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]); 
                }

                const { data } = await axios.post('/request_attend', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': this.$store.getters.GET_AUTH_TOKEN,
                    }
                })

                this.$emit('message', data.message)
                this.$emit('response', true)
                this.$emit('redirect', 'live')
                this.$emit('clicked', false)
                this.$emit('alert', true)
                this.is_processing = false
            } catch({response}){
                this.$emit('message', response.data.message)
                this.$emit('response', false)
                this.$emit('clicked', false)
                this.$emit('alert', true)

                if(response.status == 401){
                    this.$store.dispatch("REFRESH")
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(() => {
                        this.$router.push({name: 'login'})
                    })
                } else if(response.status == 406) {
                    this.$store.dispatch("LOGOUT")
                    .then(() => {
                        this.$router.push({ path : '/'});
                    }).catch(() => {
                        this.$router.push({ path : '/'});
                    });
                }
                
                this.is_processing = false
            }
        },
        closeRequest(){
            this.$emit('clicked', false);
            this.showRequestAttend = false
        },
        isInRange(){
            if ("geolocation" in navigator) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  this.currentlat = position.coords.latitude;
                  this.currentlng = position.coords.longitude;                  
                  
                  fetch(`https://nominatim.openstreetmap.org/reverse?lat=${this.currentlat}&lon=${this.currentlng}&format=json`, {
                    headers: {'User-Agent': 'ESS IRIS INTERNATIONAL'}
                  }).then(res => res.json())
                  .then(res => {

                      this.location = res.display_name;
                      this.latitude = res.lat;
                      this.longitude = res.lon;                      

                      // console.log(res.address)
                  })
                },
                (error) => {
                  alert(`Error getting location : ${error.message}`);
                  window.location.reload();
                },
                {maximumAge:10000, timeout:5000, enableHighAccuracy: true},
              );
              this.loading = false
            } else {alert("Geolocation is not supported in this browser.")}
        },
        init(){
            this.listDevices = [];
            navigator.mediaDevices.enumerateDevices().then((devices) => {
              devices.forEach((device) => {
                  const obj = {
                      device_label: '',
                      device_id: '',
                  }

                  if(device.deviceId && device.kind === "videoinput"){
                      obj.device_label = device.label;
                      obj.device_id = device.deviceId;
                      this.listDevices.push(obj);
                  }
              });
            }).catch(function (e) {
                this.$emit('message', e.name + ": " + e.message)
                this.$emit('response', false)
                this.$emit('clicked', false)
                this.$emit('alert', true)
                return;
            });
            
            this.showCamera = true;
            if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
                navigator.mediaDevices.getUserMedia({
                  audio: false, 
                  video: {
                    width: 1080, 
                    height: 1080,
                  }, 
                  facingMode: "user",
                  focusMode: "continuous",
                }).then(stream => {
                    const videoPlayer = document.querySelector("video");
                    videoPlayer.srcObject = stream;
                    videoPlayer.play();
                })
            } else {alert("mediaDevices not supported!")}
          },
        closeCam(){
            const video = document.querySelector('video');
            const mediaStream = video.srcObject;
            const tracks = mediaStream.getVideoTracks();
            tracks.forEach(track => track.stop());
            this.firstAttemp = false;
            this.showCamera = false;
          },
        capture(){
            // let ratio = (window.innerHeight > window.innerWidth) ? 16 / 9 : 9 / 16;
            const pictures = document.getElementById("selectedpicture");
            pictures.width = 500
            pictures.width = 300
            const ctx = pictures.getContext("2d");
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage(document.querySelector("video"), 0, 0, pictures.width, pictures.height);
            // ctx.scale(-1, 1);
            this.images = true;
            this.selectimages = 'Ubah Foto';
            const video = document.querySelector('video');
            const mediaStream = video.srcObject;
            const tracks = mediaStream.getVideoTracks();
            tracks.forEach(track => track.stop());
            this.is_captured = true;
            this.firstAttemp = false;
            this.showCamera = false;
        },
    }
}
</script>

<style>

</style>