<template>
    <clockin-loader v-show="loading"></clockin-loader>
    <div v-show="!loading" class="home-container">
        <navbar-vue></navbar-vue>
        <div class="body-page">
              <div class="map-container" v-show="!showCamera">
                <!-- <l-map ref="map" v-model:zoom="zoom" :center="[currPos.lat, currPos.lng]" :options="{zoomControl: false, attributionControl: false}">
                  <l-tile-layer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" layer-type="base" name="OpenStreetMap"></l-tile-layer>
                  <l-circle-marker :lat-lng="[currPos.lat, currPos.lng]" :radius="7" 
                  :color="'red'" :stroke="false" :fill="true" :fillColor="'#d40000'" :fillOpacity="1" />
                  <l-circle-marker :lat-lng="[currPos.lat, currPos.lng]" :radius="14" 
                  :color="'red'" :stroke="false" :fill="true" :fillColor="'#d40000'" :fillOpacity="0.4" />
                  <l-circle :lat-lng="[rad.lat, rad.lng]" :radius="50" :stroke="false" 
                  :color="'#0084d1'" :fill="true" :fillColor="'#0084d1'" :fillOpacity="0.5"
                  v-for="rad in location" :key="rad.id"></l-circle>
                </l-map> -->
              </div>
              <div class="clock-detail">
                  <div class="clock-input">
                        <div class="handle"></div>
  
                        <div style="width: 100%;
                                    height: 110px;
                                    margin-top: 10px;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    background: var(--bluecolor);
                                    border: 1px solid var(--bluehover);
                                    border-radius: 10px">
                            <div>
                                <img v-show="!is_captured" style="width: 120px;height: 100px;border-radius: 5px;object-fit: cover;margin: 5px" src="/assets/images/user/default.png" alt="">
                                <div v-show="is_captured" class="camera-input" @click="init">
                                    <canvas class="image-holder" id="selectedpicture"></canvas>
                                    <div class="image-text">
                                      <i style="font-size: 19pt;" class="fa-solid fa-camera" :class="{'text-white' : images}"></i>
                                      <span :class="{'text-white' : images}">{{ selectimages }}</span>
                                    </div>
                                </div>
                            </div>
                            <div style="position: relative;
                                        height: 110px;
                                        margin-left: 10px;
                                        display: flex;
                                        flex-direction: column;
                                        font-family: 'Dosis', sans-serif;
                                        align-items: start;
                                        padding: 10px 0px;
                                        color: #fff">
                                <span style="font-family: Arial, Helvetica, sans-serif;">Employee Name</span>
                                <span style="margin-top: 10px;color: #ececec">{{ myDate }}</span>
                                <span style="font-size: 21pt;margin: 0 auto;margin-top: 10px;color: var(--backcolor);font-weight: 600;">{{ myTime }}</span>
                            </div>
                        </div>
  
                        <span class="label-input" style="margin-top: 15px;">Keterangan</span>
                        <textarea name="" id="" cols="30" rows="5" class="textarea-input"></textarea>
  
                        <span style="position: absolute;
                                     bottom: -60px;
                                     left: 50%;
                                     -webkit-transform: translateX(-50%);
                                     transform: translateX(-50%);
                                     font-size: 33pt;
                                     color: var(--border);
                                     font-family: MODERA, sans-serif;">
                                     ESS IRIS
                                     <span style="font-size: 13pt;color: var(--bluehover);font-family: Arial, Helvetica, sans-serif;margin-left: -5px;">Beta</span>
                        </span>
                  </div>
                  <div class="button-container">
                        <div style="position: absolute;margin-top: 0px;left: 50%;-webkit-transform: translateX(-50%);transform: translateX(-50%);">
                          <input id="checkbox" type="checkbox">
                          <label class="switch" for="checkbox">
                              <svg viewBox="0 0 448 512" class="svg"><path d="M320 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM125.7 175.5c9.9-9.9 23.4-15.5 37.5-15.5c1.9 0 3.8 .1 5.6 .3L137.6 254c-9.3 28 1.7 58.8 26.8 74.5l86.2 53.9-25.4 88.8c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l28.7-100.4c5.9-20.6-2.6-42.6-20.7-53.9L238 299l30.9-82.4 5.1 12.3C289 264.7 323.9 288 362.7 288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H362.7c-12.9 0-24.6-7.8-29.5-19.7l-6.3-15c-14.6-35.1-44.1-61.9-80.5-73.1l-48.7-15c-11.1-3.4-22.7-5.2-34.4-5.2c-31 0-60.8 12.3-82.7 34.3L57.4 153.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l23.1-23.1zM91.2 352H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h69.6c19 0 36.2-11.2 43.9-28.5L157 361.6l-9.5-6c-17.5-10.9-30.5-26.8-37.9-44.9L91.2 352z"></path></svg>Start
                          </label>
                        </div>
  
                        <!-- <button @click="submit" class="clock-btn">Live Attendance - {{ title }}</button> -->
                  </div>
              </div>
        </div>
    </div>
  
    <div v-if="showQuestions" class="question-modal">
        <div class="screen-overlay" @click="showQuestions = false"></div>
        <div class="radio-input">
          <div class="info">
            <span class="question">{{ validation[questionCounter].question }}</span>
          </div>
          <div v-for="choice in validation[questionCounter].choice" :key="choice.id">
            <input type="radio" :id="'choice' + choice.id" name="questions" :value="choice.name" @click="checkAnswer(choice.name)">
            <label :for="'choice' + choice.id">{{ choice.name }}</label>
          </div>
          <span style="font-size: 10pt;color: red;margin-top: 5px;font-family: Arial, Helvetica, sans-serif;" v-show="is_wrong">Wrong answer!</span>
        </div>
    </div>
  
    <div v-if="showCamera" class="camera-container">
        <div class="camera-switch" @click="switchCam"><i class="fa-solid fa-camera-rotate"></i></div>
        <video class="camera" autoplay></video>
        <div class="camera-close" @click="closeCam"><i class="fa-solid fa-rotate-left"></i></div>
        <div @click="capture" class="camera-snap"><i class="fa-solid fa-camera"></i></div>
    </div>
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useGeolocation } from '@/useGeolocation';
  import "leaflet/dist/leaflet.css";
  // import { LMap, LTileLayer, LCircle, LCircleMarker } from "@vue-leaflet/vue-leaflet";
  import L from 'leaflet';
  import axios from 'axios';
  import NavbarVue from '@/components/Navbar.vue';
  import ClockinLoader from '@/components/Loader/ClockinLoader.vue';
  export default {
      name: 'AttendanceView',
      components: {
        // LMap, LTileLayer, LCircle, LCircleMarker,
        NavbarVue,
        ClockinLoader,
      },
      data(){
        return {
                title: '',
                loading: true,
                times: '00:00',
                myDate: '',
                myTime: '-- : -- : --',
                zoom: 16,
                crntpos: null,
                inRanges: false,
                showCamera: false,
                showQuestions: false,
                is_answer: false,
                is_wrong: false,
                is_processing: false,
                is_captured: false,
                selectedChoice: '',
                wrongCounter: 0,
                questionCounter: 0,
                validation: [
                    {
                      id: 1,
                      question: 'Siapa nama ibu anda ?',
                      choice: [
                        {id: 'c1', name: 'Alice'},
                        {id: 'c2', name: 'Diana'},
                        {id: 'c3', name: 'Stephanie'},
                        {id: 'c4', name: 'Tessa'},
                      ],
                      answer: 'Diana',
                    },
                    {
                      id: 2,
                      question: 'Dimana tempat lahir ibu anda ?',
                      choice: [
                        {id: 'c1', name: 'Jakarta'},
                        {id: 'c2', name: 'Balikpapan'},
                        {id: 'c3', name: 'Denpasar'},
                        {id: 'c4', name: 'Surabaya'},
                      ],
                      answer: 'Denpasar',
                    },
                    {
                      id: 3,
                      question: 'Kapan tanggal lahir ibu anda ?',
                      choice: [
                        {id: 'c1', name: '30 June 1974'},
                        {id: 'c2', name: '13 Januari 1968'},
                        {id: 'c3', name: '27 May 1971'},
                        {id: 'c4', name: '09 December 1970'},
                      ],
                      answer: '27 May 1971',
                    }
                ],
                images: false,
                image: null,
                selectimages: 'Tambah Foto',
                currentlat: 0,
                currentlng: 0,
                locCounter: 0,
                listDevices: [],
                counterDevices: 0,
                firstAttemp: false,
                device_label: 'asdasda',
                device_id: 'AA',
                device_kind: 'asdas',
                device_group: '0',
                location: [
                  {
                    id: 1,
                    name: 'location31',
                    lat: "-6.1897018",
                    lng: "106.8071084",
                  },
                  {
                    id: 2,
                    name: "tamanjati7",
                    lat: "-6.1897018",
                    lng: "106.8074080",
                  },
                  {
                    id: 3,
                    name: "location72",
                    lat: "-6.1905559",
                    lng: "106.8060936",
                  },
                  {
                    id: 4,
                    name: "location64",
                    lat: "-6.1901514",
                    lng: "106.8061906",
                  }
                ]
            }
        },
        setup(){
          const { coords } = useGeolocation();
          const currPos = computed(() => ({
            lat: coords.value.latitude,
            lng: coords.value.longitude,
          }));
          return{ currPos }
        },
        beforeRouteLeave(){this.removeMaps()},
        created(){
            if(document.getElementById("map")) this.removeMaps();
            this.createMaps();
            this.isInRange();
            setTimeout(() => {this.loading = false}, 1000);
            setInterval(() => {this.createDate()}, 1000);
            if(this.$route.name === 'clockin') this.title = 'Clock In';
            else this.title = 'Clock Out';
        },
        methods: {
            removeMaps(){document.getElementById("map").remove()},
            createMaps(){
              const newMap = document.createElement("div");
              newMap.setAttribute("id", "map");
              const parentDiv = document.getElementById("app");
              document.body.insertBefore(newMap, parentDiv);
            },
            switchCam(){ 
                if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) alert("enumerateDevices is not supported.");
                if(!this.firstAttemp && this.listDevices.length > 0){
                  this.counterDevices = 1;
                  this.firstAttemp = true;
                }
  
                if(this.counterDevices > this.listDevices.length) this.counterDevices = 0;
                if(this.listDevices[this.counterDevices] !== undefined){
                  const device_id = this.listDevices[this.counterDevices].device_id;
                  const video = document.querySelector('video');
                  const mediaStream = video.srcObject;
                  const tracks = mediaStream.getVideoTracks();
                  tracks.forEach(track => track.stop());
  
                  if(this.counterDevices > 0) document.querySelector('video').style.scale = "(-1, 1)";
                  else document.querySelector('video').style.scale = "(-1, 1)";
                  
                  if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
                      navigator.mediaDevices.getUserMedia({
                        audio: false, 
                        video: {
                          deviceId: device_id,
                          width: 720, 
                          height: 720,
                        },
                        focusMode: "continuous",
                      }).then(stream => {
                          const videoPlayer = document.querySelector("video");
                          videoPlayer.srcObject = stream;
                          videoPlayer.play();
                      })
                  } else {alert("mediaDevices not supported!")}
                }
                this.counterDevices++;
            },
            init(){
              if(this.locCounter <= 0){
                alert('Your location is out of radius');
              }
  
              this.listDevices = [];
              navigator.mediaDevices.enumerateDevices().then((devices) => {
                devices.forEach((device) => {
                    const obj = {
                        device_label: '',
                        device_id: '',
                    }
  
                    if(device.deviceId && device.kind === "videoinput"){
                        obj.device_label = device.label;
                        obj.device_id = device.deviceId;
                        this.listDevices.push(obj);
                    }
                });
              }).catch(function (e) {
                  console.log(e.name + ": " + e.message);
                  return;
              });
              
              document.getElementById("map").setAttribute("hidden", "");
              this.showCamera = true;
              if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
                  navigator.mediaDevices.getUserMedia({
                    audio: false, 
                    video: {
                      width: 720, 
                      height: 720,
                    }, 
                    facingMode: "user",
                    focusMode: "continuous",
                  }).then(stream => {
                      const videoPlayer = document.querySelector("video");
                      videoPlayer.srcObject = stream;
                      videoPlayer.play();
                  })
              } else {alert("mediaDevices not supported!")}
            },
            closeCam(){
              const video = document.querySelector('video');
              const mediaStream = video.srcObject;
              const tracks = mediaStream.getVideoTracks();
              tracks.forEach(track => track.stop());
              this.firstAttemp = false;
              this.showCamera = false;
              document.getElementById("map").removeAttribute('hidden');
            },
            capture(){
              // let ratio = (window.innerHeight > window.innerWidth) ? 16 / 9 : 9 / 16;
              const pictures = document.getElementById("selectedpicture");
              const ctx = pictures.getContext("2d");
              ctx.imageSmoothingEnabled = true;
              ctx.imageSmoothingQuality = "high";
              ctx.drawImage(document.querySelector("video"), 0, 0, pictures.width, pictures.height);
              // ctx.scale(-1, 1);
              this.images = true;
              this.selectimages = 'Ubah Foto';
              const video = document.querySelector('video');
              const mediaStream = video.srcObject;
              const tracks = mediaStream.getVideoTracks();
              tracks.forEach(track => track.stop());
              this.is_captured = true;
              this.time();
              this.firstAttemp = false;
              this.showCamera = false;
              document.getElementById("map").removeAttribute('hidden');            
            },
            isInRange(){
              if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    this.currentlat = position.coords.latitude;
                    this.currentlng = position.coords.longitude;                  
                    var map = L.map('map', {
                        center: [this.currentlat, this.currentlng],
                        zoom: 16,
                        zoomControl: false,
                    });
  
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        maxZoom: 19, 
                        attribution: '© OpenStreetMap'
                    }).addTo(map);
  
                    var crntpos = new L.circle([parseFloat(this.currentlat), parseFloat(this.currentlng)], { 
                      radius: 7, 
                      color: 'red',
                      stroke: false,
                      fill: true,
                      fillColor: '#b22a2e',
                      fillOpacity: 0.8,
                    }).addTo(map);
  
                    new L.circle([parseFloat(this.currentlat), parseFloat(this.currentlng)], { 
                      radius: 14, 
                      color: 'red',
                      stroke: false,
                      fill: true,
                      fillColor: '#b22a2e',
                      fillOpacity: 0.4,
                    }).addTo(map);
  
                    this.locCounter = 0;
                    this.location.forEach((el) => {
                        var radloc = new L.circle([el.lat, el.lng], {
                          radius: 60, 
                          color: 'blue',
                          stroke: false,
                          fill: true,
                          fillColor: '#009dff',
                          fillOpacity: 0.4,
                        }).addTo(map);
                        var cenloc = radloc.getLatLng();
                        if (cenloc.distanceTo(crntpos.getLatLng()) <= radloc.getRadius()) this.locCounter += 1;
                    });
                    if(this.locCounter > 0) console.log('Position in Range');
                    else console.log('Your location is out of radius');
                  },
                  (error) => {
                    alert(`Error getting location : ${error.message}`);
                    window.location.reload();
                  },
                  {maximumAge:10000, timeout:5000, enableHighAccuracy: true},
                );
              } else {alert("Geolocation is not supported in this browser.")}
            },
            checkAnswer(choice){
              if(this.is_processing) return;
              this.is_processing = true;
              if(this.validation[this.questionCounter].answer !== choice) {
                this.wrongCounter++;
                this.is_wrong = true;
  
                setTimeout(() => {
                  this.is_wrong = false;
                }, 1000);
              } else {
                this.is_answer = true;
                this.selectedChoice = 'choice';
                this.showQuestions = false;
              }
              
              if(this.wrongCounter > 2) {
                this.showQuestions = false;
                alert("You answered wrong 3 times!");
                this.questionCounter++;
                this.wrongCounter = 0;
                return;
              }
  
              this.is_processing = false;
            },
            submit(){
              if(!this.is_captured){
                  this.showCamera = true;
                  this.init();
                  return;
              }
  
              if(this.questionCounter == this.validation.length) this.questionCounter = 0;
              if(!this.is_answer){
                this.showQuestions = true;
                return;
              }
  
              const pictures = document.getElementById("selectedpicture");
              const getImg = pictures.toDataURL("image/png");
              var byteString = atob(getImg.split(',')[1]);
              var mimeString = getImg.split(',')[0].split(':')[1].split(';')[0]
              var ab = new ArrayBuffer(byteString.length);
              var ia = new Uint8Array(ab);
              for (var i = 0; i < byteString.length; i++) {
                  ia[i] = byteString.charCodeAt(i);
              }
  
              const fName = 'Attendance' + (Math.floor(Date.now() / 1000)) + '.png';
              var myBlob = new Blob([ab], {type: mimeString});
              const myFile = new File([myBlob], fName, {
                  type: myBlob.type,
              });
  
              const formData = new FormData();
              formData.append('files', myFile);
              axios.post('/image/' + fName, formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'Access-Control-Allow-Origin': '*',
                      'Accept': 'application/json',
                  }
              }).then((res) => {
                  console.log(res);
                  window.location.href = "https://139.0.5.108:8083/api/web/image/" + fName;
              }).catch((err) => {console.log(err)});
            },
            time() {
                var d = new Date();
                var s = d.getSeconds();
                var m = d.getMinutes();
                var h = d.getHours();
                this.myTime = ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2) + ":" + ("0" + s).substr(-2);
            },
            createDate(){
              var d = new Date();
              var day = d.getDate();
              var year = d.getFullYear();
              switch (new Date().getDay()) {
              case 0:
                  this.day = "Sunday";
                  break;
              case 1:
                  this.day = "Monday";
                  break;
              case 2:
                  this.day = "Tuesday";
                  break;
              case 3:
                  this.day = "Wednesday";
                  break;
              case 4:
                  this.day = "Thursday";
                  break;
              case 5:
                  this.day = "Friday";
                  break;
              case 6:
                  this.day = "Saturday";
              }
  
              switch (new Date().getMonth()) {
              case 0:
                  this.month = "January";
                  break;
              case 1:
                  this.month = "February";
                  break;
              case 2:
                  this.month = "March";
                  break;
              case 3:
                  this.month = "April";
                  break;
              case 4:
                  this.month = "May";
                  break;
              case 5:
                  this.month = "June";
                  break;
              case 6:
                  this.month = "July";
                  break;
              case 7:
                  this.month = "August";
                  break;
              case 8: 
                  this.month = "September"
                  break;
              case 9:
                  this.month = "October"
                  break;
              case 10:
                  this.month = "November"
                  break;
              case 11:
                  this.month = "December"
              }
  
              this.myDate = this.day + ", " + day + " " + this.month + " " + " " + year;
            }
        }
  }
  </script>
  
  <style>
  .question-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .question-modal .screen-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--screenoverlay);
    z-index: 1;
  }
  
  .radio-input input {
    display: none;
  }
  
  .radio-input {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 5px;
    background: #fff;
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 87px 78px -39px rgba(0,0,0,0.4);
    font-family: Arial, Helvetica, sans-serif;
    width: 320px;
    height: 420px;
    z-index: 2;
  }
  
  .info {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .question {
    color: rgb(49, 49, 49);
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 600;
  }
  
  .steps {
    background-color: rgb(0, 0, 0);
    padding: 4px;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
  }
  
  .radio-input  label {
    display: flex;
    background-color: #fff;
    padding: 14px;
    margin: 8px 0 0 0;
    font-size: 13px;
    font-weight: 600;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    border: 1.5px solid var(--border);
    color: #000;
    transition: .3s ease;
    display: flex;
    align-items: center;
  }
  
  .radio-input  label:hover {
    background-color: rgba(24, 24, 24, 0.13);
    border: 1px solid #bbb;
  }
  
  .result {
    margin-top: 10px;
    font-weight: 600;
    font-size: 12px;
    display: none;
    transition: display .4s ease;
  }
  
  .result.success {
    color: green;
  }
  
  .result.error {
    color: red;
  }
  
  .radio-input input:checked + label {
    border: 2px solid var(--bluecolor);
    color: var(--bluehover);
  }
  
  .radio-input:has(input[value="value-2"]:checked) .result.success {
    display: flex;
  }
  
  .radio-input:has(input:not([value="value-2"]):checked ) .result.error {
    display: flex;
  }
  
  /*  */
  #checkbox {
    display: none;
  }
  
  .switch {
    position: relative;
    width: 90px;
    height: 90px;
    background-color: rgb(99, 99, 99);
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 0.9em;
    font-weight: 700;
    color: rgb(37, 37, 37);
    border: 2px solid rgb(126, 126, 126);
    box-shadow: 0px 0px 3px rgb(2, 2, 2) inset;
  }
  
  .svg {
    position: relative;
    width: 30%;
    height: 30%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .svg path {
    fill: rgb(37, 37, 37);
  }
  
  #checkbox:checked + .switch .svg {
    filter: drop-shadow(0px 0px 3px var(--backcolor));
  }
  
  #checkbox:checked + .switch .svg path {
    fill: rgb(255, 255, 255);
  }
  
  #checkbox:checked + .switch {
    box-shadow: 0px 0px 1px var(--backcolor) inset,
        0px 0px 2px var(--backcolor) inset,
        0px 0px 10px var(--backcolor) inset,
        0px 0px 40px var(--backcolor),
        0px 0px 100px var(--backcolor),
        0px 0px 5px var(--backcolor);
    border: 2px solid rgb(255, 255, 255);
    background-color: var(--backcolor);
    color: rgb(255, 255, 255);
  }
  </style>