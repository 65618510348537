<template>
    <live-attendance-loader v-show="loading"></live-attendance-loader>
    <div v-show="!loading" class="home-container">
      <div class="top-page">
          <div class="profile-section">
              <div class="navigation-name">
                  <div style="width: 20%;">
                    <router-link :to="{name: 'home'}"><i class="fa-solid fa-arrow-left"></i></router-link>
                  </div>
                  <div style="width: 60%;display: flex;flex-direction: row ;justify-content: center;">
                    <span>Visit Attendance</span>
                  </div>
              </div>
          </div>
          <div class="time-section">
              <span class="time">{{ times }}</span>  
              <span class="date">{{ myDate }}</span>
          </div>
          <div class="clocking-section">
              <span class="title">Belum Ada Jadwal</span>
              <div class="line"></div>
              <div class="button-wrapper">
                    <button @click="checkAttend(true, nik, this.$route.name)" class="attendance-button">
                        <lord-icon
                            src="https://cdn.lordicon.com/osvvqecf.json"
                            trigger="loop"
                            colors="outline:#545454,primary:#ff725e,secondary:#ffffff,tertiary:#ffffff"
                            stroke="80"
                            style="width:50px;height:50px">
                        </lord-icon>
                        <span style=" margin-left: 0px">Clock In</span>
                    </button>
                    <button @click="checkAttend(false, nik, this.$route.name)"  class="attendance-button">
                        <lord-icon
                            src="https://cdn.lordicon.com/mdbykuhq.json"
                            trigger="loop"
                            colors="outline:#545454,primary:#ff725e,secondary:#ff725e,tertiary:#ffffff"
                            stroke="90"
                            style="width:50px;height:50px">
                        </lord-icon>
                        <span style="margin-left: 0px">Clock Out</span>
                    </button>
              </div>
          </div>
      </div>
      <div class="body-page" style="margin-top: -40px;">
          <div class="body-section">
                <div class="title" style="margin-top: 50px;">
                  <h3>Attendance Log</h3>
                  <select class="month-pick" name="" id="" v-model="period">
                    <option v-for="mth in months" 
                            :key="mth.id" :value="mth.id">
                                {{ mth.month }}
                        </option>
                  </select>
                </div>

                <div style="height: 55vh;overflow-x: hidden;overflow-y: scroll;padding-top: 10px;margin-top: -20px;">
                    <div v-for="attend in present" :key="attend.id">
                        <div class="clock-history" v-if="attend.attend.length > 0">
                            <router-link class="day-detail" :to="{name: 'attendance-list', params: {date: attend.dates}}"
                                        v-if="attend.attendance_total > 2">
                                <i class="fa-solid fa-angles-right"></i>
                            </router-link>

                            <div class="date">
                                <span style="font-family: 'Delight Coffee', sans-serif;font-size: 21pt;font-weight: 400;margin: 0">
                                    {{ new Date(attend.dates).getDate() }}
                                </span>
                                <span style="font-family: 'KEBON', sans-serif;font-size: 15pt;margin-top: -8px">
                                    {{ convertMonth(new Date(attend.dates).getMonth()).substring(0, 3) }}
                                </span>
                            </div>
                            
                            <div class="clocking">
                                <span style="font-weight: 400;font-size: 10pt;color: #7e7e7e">Clock In</span>
                                <span style="font-weight: bolder;margin-top: 15px;color: var(--backcolor)" v-if="attend.attend[0]">{{ attend.attend[0].attendance_time }}</span>
                                <span style="font-weight: bolder;margin-top: 15px;color: var(--backcolor)" v-if="!attend.attend[0]">-- : -- : --</span>
                                <router-link :to="{name: 'detail', params: { id: attend.attend[0].id}}" style="font-size: 10pt;text-decoration: none;color: #00BCD4;margin-top: 5px">Details</router-link>
                            </div>
                            <div class="clocking">
                                <span style="font-weight: 400;font-size: 10pt;color: #7e7e7e">Clock Out</span>
                                <span style="font-weight: bolder;margin-top: 15px;color: var(--backcolor)" v-if="attend.attend[1]">{{ attend.attend[1].attendance_time }}</span>
                                <span style="font-weight: bolder;margin-top: 15px;color: var(--backcolor)" v-if="!attend.attend[1]">-- : -- : --</span>
                                <router-link v-if="attend.attend[1]" :to="{name: 'detail', params: { id: attend.attend[1].id}}" style="font-size: 10pt;text-decoration: none;color: #00BCD4;margin-top: 5px">Details</router-link>
                            </div>
                        </div>
                    </div>
                </div>

          </div>
      </div>
  </div>
</template>

<script>
import LiveAttendanceLoader from '@/components/Loader/LiveAttendanceLoader.vue';
import axios from 'axios';

export default {
    name: 'VisitAttendance',
    components: {
        LiveAttendanceLoader
    },
    data(){
        return {
            times: '00:00:00',
            myDate: '',
            day: '',
            month: '',
            loading: true,
            nik: 0,
            period: 8,
            present: [],
            months: [
                {id: 0, month: "January"},
                {id: 1, month: "February"},
                {id: 2, month: "March"},
                {id: 3, month: "April"},
                {id: 4, month: "May"},
                {id: 5, month: "June"},
                {id: 6, month: "July"},
                {id: 7, month: "August"},
                {id: 8, month: "September"},
                {id: 9, month: "October"},
                {id: 10, month: "November"},
                {id: 11, month: "December"},
            ]
        }
    },
    created(){
        this.nik = this.$store.getters.GET_AUTH_INFO.nik
        this.period = new Date().getMonth()
        this.getAttendance()
        setTimeout(() => { this.loading = false }, 1000);
        setInterval(() => { this.time()}, 1000);
        setInterval(() => { this.dates()}, 1000);
    },
    methods: {
        async getAttendance(){
            try {
                const { data } = await axios.get('/attendance/all/' + this.$route.name + '/' + this.period, {
                    headers: {
                        'Authorization' : this.$store.getters.GET_AUTH_TOKEN,
                    }
                })

                this.present = data.data
            } catch(err){
                if(err.response.status == 401){
                    this.$store.dispatch("REFRESH")
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(() => {
                        this.$router.push({name: 'login'})
                    })
                }
            }
        },
        async checkAttend(clocking, nik, type){
            try{
                const body = {
                    clocking: clocking,
                    nik: nik,
                    type: type,
                }                

                await axios.post('/check_attend', body, {
                    headers: {
                        'Authorization' : this.$store.getters.GET_AUTH_TOKEN
                    }
                })

                if(clocking){
                    this.$router.push({name: 'clockin', params: {types: type}})
                } else {
                    this.$router.push({name: 'clockout', params: {types: type}})
                }
            } catch(e){
                if(e.response.status == 400){
                    alert(e.response.data.message)
                }

                if(e.response.status == 401){
                  this.$store.dispatch("REFRESH")
                  .then(() => {
                      window.location.reload()
                  })
                  .catch(() => {
                      this.$router.push({name: 'login'})
                  })
                }
            }
        },
        time() {
            var d = new Date();
            var s = d.getSeconds();
            var m = d.getMinutes();
            var h = d.getHours();
            this.times = ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2) + ":" + ("0" + s).substr(-2);
        },
        convertMonth(value){
            var month;
            switch (value) {
            case 0:
                month = "January";
                break;
            case 1:
                month = "February";
                break;
            case 2:
                month = "March";
                break;
            case 3:
                month = "April";
                break;
            case 4:
                month = "May";
                break;
            case 5:
                month = "June";
                break;
            case 6:
                month = "July";
                break;
            case 7:
                month = "August";
                break;
            case 8: 
                month = "September"
                break;
            case 9:
                month = "October"
                break;
            case 10:
                month = "November"
                break;
            case 11:
                month = "December"
            }

            return month;
        },
        dates(){
            var d = new Date();
            var day = d.getDate();
            var year = d.getFullYear();
            switch (new Date().getDay()) {
            case 0:
                this.day = "Sunday";
                break;
            case 1:
                this.day = "Monday";
                break;
            case 2:
                this.day = "Tuesday";
                break;
            case 3:
                this.day = "Wednesday";
                break;
            case 4:
                this.day = "Thursday";
                break;
            case 5:
                this.day = "Friday";
                break;
            case 6:
                this.day = "Saturday";
            }

            switch (new Date().getMonth()) {
            case 0:
                this.month = "January";
                break;
            case 1:
                this.month = "February";
                break;
            case 2:
                this.month = "March";
                break;
            case 3:
                this.month = "April";
                break;
            case 4:
                this.month = "May";
                break;
            case 5:
                this.month = "June";
                break;
            case 6:
                this.month = "July";
                break;
            case 7:
                this.month = "August";
                break;
            case 8: 
                this.month = "September"
                break;
            case 9:
                this.month = "October"
                break;
            case 10:
                this.month = "November"
                break;
            case 11:
                this.month = "December"
            }

            this.myDate = this.day + ", " + day + " " + this.month + " " + " " + year;
        },  
    }
}
</script>

<style>

</style>