<template>
    <div class="alert-container">
        <div class="alert-box">
            <i v-if="!status" class="fa-solid fa-square-xmark alert-close" @click="closeAlert"></i>
            <lord-icon
                v-if="!status"
                src="https://cdn.lordicon.com/vsgcujbd.json"
                trigger="loop"
                colors="primary:#ff2c2c,secondary:#545454,tertiary:#ebe6ef"
                stroke="90"
                style="position: relative;top:0;width:100px;height:100px;margin: 0 auto">
            </lord-icon>

            <lord-icon
                v-if="status"
                src="https://cdn.lordicon.com/ivayzoru.json"
                trigger="loop"
                colors="outline:#545454,primary:#ffffff,secondary:#30c9e8"
                stroke="90"
                style="position: relative;top:0;width:85px;height:85px;margin: 0 auto">
            </lord-icon>

            <div class="alert-message">
                <p>{{ message }}</p>
            </div>

            <button class="confirm-btn" @click="confirm">OK</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'AlertVue',
    props: ['message', 'status', 'type', 'action', 'data'],
    methods:{
        closeAlert(){
            this.$emit('clicked', false)
        },
        confirm(){
            if(this.action == 'relogin'){
                axios.post('/relogin', this.data)
                .then(() => { 
                    this.$store.dispatch("LOGIN", this.data)
                    .then(() => {
                        this.is_proccess = false
                        this.$router.push({ name : 'home'});
                    }).catch((error) => {
                        console.log(error)
                    })  
                })
                .catch((err) => { console.log(err) })
                return;
            } else {
                if(this.status) this.$router.push({name: this.type});
                else this.closeAlert();
            }
        }
    }
}
</script>

<style>

</style>