<template>
    <leave-request-loader v-if="loading"></leave-request-loader>
    <div v-if="!loading" class="home-container">
        <navbar title="Leave Request"></navbar>
        <div class="body-page" style="margin-top: 60px">
            <div class="body-section">
                <div class="attendance-report">
                    <span class="report-title">August</span>
                    <router-link :to="{name: 'live'}" class="report-nav">View</router-link>
                    <div class="report-box">
                            <span class="count">3</span>
                            <span class="desc">Approved</span>
                    </div>
                    <div class="report-box">
                            <span class="count">1</span>
                            <span class="desc">Rejected</span>
                    </div>
                    <div class="report-box">
                            <span class="count">1</span>
                            <span class="desc">Pending</span>
                    </div>
                    <div class="report-box">
                            <span class="count">5</span>
                            <span class="desc">Total</span>
                    </div>
                </div>

                <router-link :to="{name: 'add-leave'}" class="leave-button">
                    <lord-icon
                        src="https://cdn.lordicon.com/qtqvorle.json"
                        trigger="loop"
                        colors="outline:#00bcd4,primary:#00bcd4,secondary:#ffffff,tertiary:#ff725e"
                        stroke="90"
                        style="width:40px;height:40px">
                    </lord-icon>
                    Create Leave Request
                </router-link>

                <div class="leave-item">
                    <div class="leave-card">
                        <span class="leave-title">Paid Leave</span>
                        <span class="leave-date">23/08/2023</span>
                        <span class="leave-period">2 Days Leave</span>
                        <div class="leave-status-success">Approved</div>
                        <a href="" class="leave-detail">View</a>
                    </div>

                    <div class="leave-card">
                        <span class="leave-title">Paid Leave</span>
                        <span class="leave-date">25/08/2023</span>
                        <span class="leave-period">1 Days Leave</span>
                        <div class="leave-status-fail">Rejected</div>
                        <a href="" class="leave-detail">View</a>
                    </div>

                    <div class="leave-card">
                        <span class="leave-title">Maternity Leave</span>
                        <span class="leave-date">28/08/2023</span>
                        <span class="leave-period">3 Month Leave</span>
                        <div class="leave-status-pending">Pending</div>
                        <a href="" class="leave-detail">View</a>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import LeaveRequestLoader from '@/components/Loader/LeaveRequestLoader.vue';
export default {
    name: 'LeaveRequestView',
    components: {
        Navbar, LeaveRequestLoader,
    },
    data(){
        return{
            loading: true,
        }
    },
    created(){
        setTimeout(() => {this.loading = false}, 1000);
    }
}
</script>

<style>
.leave-button{
    position: absolute;
    top: 145px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 94%;
    height: 40px;
    border-radius: 10px;
    border: 1.5px solid var(--bluecolor);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 13pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--bluecolor);
    cursor: pointer;
}

.leave-item{
    position: absolute;
    top: 200px;
    left: 40%;
    -webkit-transform: translateX(-40%);
    transform: translateX(-40%);
    width: 90%;
    padding: 0;
}

@media only screen and (max-width: 767px){
    .leave-item{
        position: absolute;
        top: 200px;
        left: 40%;
        -webkit-transform: translateX(-40%);
        transform: translateX(-40%);
        width: 88%;
        padding: 0;
        margin-left: -10px;
    }   
}

.leave-item .leave-card{
    position: relative;
    width: 100%;
    height: 70px;
    border:  1px solid var(--border);
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 15px;
    margin-top: 10px;
}

.leave-item .leave-card .leave-title{
    font-size: 12pt;
    font-weight: bold;
    color: var(--bluecolor);
}

.leave-item .leave-card .leave-date{
    margin-top: 15px;
    font-size: 12pt;
    font-weight: bold;
    color: #474747;
}

.leave-item .leave-card .leave-period{
    margin-top: 3px;
    font-size: 10pt;
    color: #e0e0e0;
    font-weight: 400;
}

.leave-item .leave-card .leave-status-success{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #00e28f;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.leave-item .leave-card .leave-status-fail{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #b50000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.leave-item .leave-card .leave-status-pending{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #ffed2b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.leave-item .leave-card .leave-detail{
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: #838383;
    font-size: 10pt;
}
</style>