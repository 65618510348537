<template>
  <live-attendance-loader v-show="loading"></live-attendance-loader>
  <div v-show="!loading" class="home-container">
      <div class="top-page" :style="{backgroundColor: basecolor}">
          <div class="profile-section">
              <div class="navigation-name">
                  <div style="width: 20%;">
                    <router-link :to="{name: 'home'}"><i class="fa-solid fa-arrow-left"></i></router-link>
                  </div>
                  <div style="width: 60%;display: flex;flex-direction: row ;justify-content: center;">
                    <span>Live Attendance</span>
                  </div>
              </div>
          </div>
          <div class="time-section">
              <span class="time">{{ times }}</span>  
              <span class="date">{{ myDate }}</span>
          </div>
          <div class="clocking-section">
              <span class="title">Belum Ada Jadwal</span>
              <div class="line"></div>
              <div class="button-wrapper">
                    <button @click="checkAttend(true, nik)" class="attendance-button">
                        <lord-icon
                            src="https://cdn.lordicon.com/osvvqecf.json"
                            trigger="loop"
                            colors="outline:#545454,primary:#ff725e,secondary:#ffffff,tertiary:#ffffff"
                            stroke="80"
                            style="width:50px;height:50px">
                        </lord-icon>
                        <span style=" margin-left: 0px">Clock In</span>
                    </button>
                    <button @click="checkAttend(false, nik)"  class="attendance-button">
                        <lord-icon
                            src="https://cdn.lordicon.com/mdbykuhq.json"
                            trigger="loop"
                            colors="outline:#545454,primary:#ff725e,secondary:#ff725e,tertiary:#ffffff"
                            stroke="90"
                            style="width:50px;height:50px">
                        </lord-icon>
                        <span style="margin-left: 0px">Clock Out</span>
                    </button>
              </div>
          </div>
      </div>
      
      <div class="body-page" style="margin-top: -40px;">
          <div class="body-section">
              <div class="title" style="margin-top: 50px;">
                  <h3>Attendance Log</h3>
                  <select class="month-pick" name="" id="" v-model="period" @change="getAttendance">
                        <option v-for="mth in months" 
                                :key="mth.id" :value="mth.id">
                                    {{ mth.month }}
                        </option>
                  </select>
              </div>
          </div>
      </div>

      <div style="width: 100%;height: 55vh;overflow-x: hidden;overflow-y: scroll;padding-top: 10px;margin-top: -20px;">
            <div style="width: 100%;padding: 0" v-for="attend in present" :key="attend.id">
                <div class="clock-history" v-if="attend.attend.length > 0">
                    <router-link class="day-detail" :to="{name: 'attendance-list', params: {date: attend.dates}}"
                                v-if="attend.attendance_total > 2">
                        <i class="fa-solid fa-angles-right"></i>
                    </router-link>

                    <div class="date">
                        <span style="font-family: Randome, sans-serif;font-size: 21pt;font-weight: 400;margin: 0">
                            {{ new Date(attend.dates).getDate() }}
                        </span>
                        <span style="font-family: Randome;font-size: 15pt;margin-top: -8px">
                            {{ convertMonth(new Date(attend.dates).getMonth()).substring(0, 3) }}
                        </span>
                    </div>
                    
                    <div class="clocking">
                        <span style="font-weight: 400;font-size: 10pt;color: #7e7e7e">Clock In</span>
                        <span style="font-weight: bolder;margin-top: 15px;color: var(--backcolor)" v-if="attend.attend[0].clocking">{{ attend.attend[0].attendance_time }}</span>
                        <span style="font-weight: bolder;margin-top: 15px;color: var(--backcolor)" v-if="attend.attend.length == 1 && attend.attend[0].clocking == false">-- : -- : --</span>
                        <router-link v-if="attend.attend[0].clocking == true" :to="{name: 'detail', params: { id: attend.attend[0].id}}" style="font-size: 10pt;text-decoration: none;color: #00BCD4;margin-top: 5px">Details</router-link>
                    </div>
                    
                    <div class="clocking">
                        <span style="font-weight: 400;font-size: 10pt;color: #7e7e7e">Clock Out</span>
                        <span style="font-weight: bolder;margin-top: 15px;color: var(--backcolor)" v-if="attend.attend.length > 1">{{ attend.attend[1].attendance_time }}</span>
                        <span style="font-weight: bolder;margin-top: 15px;color: var(--backcolor)" v-if="attend.attend.length == 1 && attend.attend[0].clocking == false">{{ attend.attend[0].attendance_time }}</span>
                        <span style="font-weight: bolder;margin-top: 15px;color: var(--backcolor)" v-if="attend.attend.length == 1 && attend.attend[0].clocking == true">-- : -- : --</span>
                        <router-link v-if="attend.attend.length > 1" :to="{name: 'detail', params: { id: attend.attend[1].id}}" style="font-size: 10pt;text-decoration: none;color: #00BCD4;margin-top: 5px">Details</router-link>
                        <router-link v-if="attend.attend.length == 1 && attend.attend[0].clocking == false" :to="{name: 'detail', params: { id: attend.attend[0].id}}" style="font-size: 10pt;text-decoration: none;color: #00BCD4;margin-top: 5px">Details</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <alert-vue v-if="showAlert" 
        :message="message" 
        :status="status" 
        :type="types"
        @clicked="onClosedAlert">
    </alert-vue>
</template>

<script>
import LiveAttendanceLoader from '@/components/Loader/LiveAttendanceLoader.vue';
import AlertVue from '@/components/Alert.vue';
import axios from 'axios';

export default {
    name: 'LiveAttendance',
    components: {
        LiveAttendanceLoader,
        AlertVue,
    },
    data(){
        return {
            times: '00:00:00',
            nik: 0,
            myDate: '',
            day: '',
            month: '',
            basecolor: '',
            loading: true,
            period: 0,
            present: [],
            months: [
                {id: 0, month: "January"},
                {id: 1, month: "February"},
                {id: 2, month: "March"},
                {id: 3, month: "April"},
                {id: 4, month: "May"},
                {id: 5, month: "June"},
                {id: 6, month: "July"},
                {id: 7, month: "August"},
                {id: 8, month: "September"},
                {id: 9, month: "October"},
                {id: 10, month: "November"},
                {id: 11, month: "December"},
            ],
            showAlert: false,
            message: null,
            status: false,
            types: null,
        }
    },
    created(){
        this.nik = this.$store.getters.GET_AUTH_INFO.nik
        this.period = new Date().getMonth()
        this.getAttendance()
        this.basecolor = localStorage.getItem('basecolor');
        setInterval(() => { this.time()}, 1000);
        setInterval(() => { this.dates()}, 1000);
    },
    methods: {
        onClosedAlert(value){this.showAlert = value},
        async getAttendance(){
            try {
                const { data } = await axios.get('/attendance/all/' + this.period, {
                    headers: {
                        'Authorization' : this.$store.getters.GET_AUTH_TOKEN,
                    }
                })

                this.present = data.data
                this.loading = false
            } catch(err){
                if(err.response.status == 401){
                    this.$store.dispatch("REFRESH")
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(() => {
                        this.$router.push({name: 'login'})
                    })
                } else if(err.response.status == 406) {
                    this.$store.dispatch("LOGOUT")
                    .then(() => {
                        this.$router.push({ path : '/'});
                    }).catch(() => {
                        this.$router.push({ path : '/'});
                    });
                }
            }
        },
        async checkAttend(clocking, nik){
            try{
                const body = {
                    clocking: clocking,
                    nik: nik,
                }

                await axios.post('/check_attend', body, {
                    headers: {
                        'Authorization' : this.$store.getters.GET_AUTH_TOKEN
                    }
                })

                if(clocking){
                    this.$router.push({name: 'clockin'})
                } else {
                    this.$router.push({name: 'clockout'})
                }
            } catch(e){
                if(e.response.status == 400){
                    this.message = e.response.data.message
                    this.status = false
                    this.showAlert = true
                }

                if(e.response.status == 401){
                  this.$store.dispatch("REFRESH")
                  .then(() => {
                      window.location.reload()
                  })
                  .catch(() => {
                      this.$router.push({name: 'login'})
                  })
                }
            }
        },
        time() {
            var d = new Date();
            var s = d.getSeconds();
            var m = d.getMinutes();
            var h = d.getHours();
            this.times = ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2) + ":" + ("0" + s).substr(-2);
        },
        convertMonth(value){
            var month;
            switch (value) {
            case 0:
                month = "January";
                break;
            case 1:
                month = "February";
                break;
            case 2:
                month = "March";
                break;
            case 3:
                month = "April";
                break;
            case 4:
                month = "May";
                break;
            case 5:
                month = "June";
                break;
            case 6:
                month = "July";
                break;
            case 7:
                month = "August";
                break;
            case 8: 
                month = "September"
                break;
            case 9:
                month = "October"
                break;
            case 10:
                month = "November"
                break;
            case 11:
                month = "December"
            }

            return month;
        },
        dates(){
            var d = new Date();
            var day = d.getDate();
            var year = d.getFullYear();
            switch (new Date().getDay()) {
            case 0:
                this.day = "Sunday";
                break;
            case 1:
                this.day = "Monday";
                break;
            case 2:
                this.day = "Tuesday";
                break;
            case 3:
                this.day = "Wednesday";
                break;
            case 4:
                this.day = "Thursday";
                break;
            case 5:
                this.day = "Friday";
                break;
            case 6:
                this.day = "Saturday";
            }

            switch (new Date().getMonth()) {
            case 0:
                this.month = "January";
                break;
            case 1:
                this.month = "February";
                break;
            case 2:
                this.month = "March";
                break;
            case 3:
                this.month = "April";
                break;
            case 4:
                this.month = "May";
                break;
            case 5:
                this.month = "June";
                break;
            case 6:
                this.month = "July";
                break;
            case 7:
                this.month = "August";
                break;
            case 8: 
                this.month = "September"
                break;
            case 9:
                this.month = "October"
                break;
            case 10:
                this.month = "November"
                break;
            case 11:
                this.month = "December"
            }

            this.myDate = this.day + ", " + day + " " + this.month + " " + " " + year;
        },  
    }
}
</script>

<style>
.clock-history{
    position: relative;
    width: 90%; 
    height: 80px;
    margin: 0 auto;
    background: #ffffff; 
    border-radius: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px;
    margin-top: -10px;
    border: 1px solid var(--border);
    /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}

.clock-history .date{
    width: 20%;
    height: 100%;
    background: var(--backcolor); 
    /* background: var(--logincolor); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.clock-history .clocking{
    width: 33%;
    height: 100%;
    padding: 10px;
    display:flex; 
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    display:flex;
    flex-direction: column;
}

.day-detail {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 35px;
    background: var(--border);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    text-decoration: none;
}
</style>