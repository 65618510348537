import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/main.css';
import axios from 'axios';
import VueCookies from 'vue-cookies';

const app = createApp(App);
axios.defaults.baseURL = 'https://attendance.tamani-pos.online/api/v1';
app.config.globalProperties.uri = 'https://attendance.tamani-pos.online'

// axios.defaults.baseURL = 'http://localhost:5000/api/v1';
// app.config.globalProperties.uri = 'http://localhost:5000'

app.config.devtools = false;
app.use(VueCookies)
app.use(store).use(router).mount('#app')
