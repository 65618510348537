<template>
  <div class="home-container">
      <navbar title="Setting"></navbar>
      <div class="body-page">
          <div class="body-section mt-60">
              <router-link :to="{name: 'profile'}" class="menu">
                  <div class="icon">
                    <lord-icon
                        src="https://cdn.lordicon.com/vusrdugn.json"
                        trigger="loop"
                        colors="primary:#545454,secondary:#00bcd4,tertiary:#ff725e,quaternary:#f9c9c0,quinary:#ffffff"
                        style="width:40px;height:40px">
                    </lord-icon>
                  </div>
                  <div class="desc">
                    <span>Edit Profile</span>
                  </div>
              </router-link>
              <router-link :to="{name: 'password'}" class="menu">
                  <div class="icon">
                    <lord-icon
                        src="https://cdn.lordicon.com/dykrlspk.json"
                        trigger="loop"
                        colors="outline:#ffffff,primary:#ffffff,secondary:#ff725e"
                        stroke="90"
                        style="width:40px;height:40px">
                    </lord-icon>
                  </div>
                  <div class="desc">
                    <span>Ganti Kata Sandi</span>
                  </div>
              </router-link>
              <a @click="logout"  class="menu">
                  <div class="icon">
                    <lord-icon
                        src="https://cdn.lordicon.com/twopqjaj.json"
                        trigger="loop"
                        colors="primary:#545454,secondary:#ffffff,tertiary:#f9c9c0,quaternary:#545454,quinary:#00bcd4,senary:#545454,septenary:#ff725e"
                        style="width:40px;height:40px">
                    </lord-icon>
                  </div>
                  <div class="desc">
                    <span>Logout</span>
                  </div>
              </a>
          </div>
      </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
    name: 'SettingView',
    components: { Navbar },
    methods: {
      logout(){
        this.$store.dispatch("LOGOUT")
        .then(() => {
            this.$router.push({ path : '/'});
        }).catch(() => {
            this.$router.push({ path : '/'});
        });
      }
    }
}
</script>

<style>

</style>