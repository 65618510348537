<template>
    <leave-approval-loader v-if="loading"></leave-approval-loader>
    <div v-if="!loading">
        <navbar title="Leave Request"></navbar>
        <div class="create-request">
            <div class="request-card">
                    <span class="request-title">Leave Request Approval</span>
                    
                    <div style="position: relative;width: 95%;height: 300px;border: 1px solid var(--border);display: flex;flex-direction: column;font-size: 9pt;padding: 5px">
                        <span>FORMULIR IZIN / SAKIT</span>
                        <span>PT. SARI TIRTA INDONESIA</span>

                        <div style="position:relative;margin-top: 20px">Name  <div style="position: absolute;left: 90px;top: 0;">: ..................................................................</div></div>
                        <span style="position: absolute;top: 50px;left: 110px;color: #515151;">John Doe</span>

                        <div style="position:relative;margin-top: 7px">NIK  <div style="position: absolute;left: 90px;top: 0;">: ..................................................................</div></div>
                        <span style="position: absolute;top: 72px;left: 110px;color: #515151;">B2201314</span>
                        
                        <div style="position:relative;margin-top: 7px">Department  <div style="position: absolute;left: 90px;top: 0;">: ..................................................................</div></div>
                        <span style="position: absolute;top: 92px;left: 110px;color: #515151;">Software Development</span>
                        
                        <div style="position:relative;margin-top: 7px">Absent Date  <div style="position: absolute;left: 90px;top: 0;">: ..................................................................</div></div>
                        <span style="position: absolute;top: 115px;left: 115px;color: #515151;">29-08-2023</span>
                        
                        <div style="position:relative;margin-top: 7px">Leave Type  <div style="position: absolute;left: 90px;top: 0;">: ..................................................................</div></div>
                        <span style="position: absolute;top: 138px;left: 110px;color: #515151;">Paid Leave</span>

                        <div style="position:relative;margin-top: 7px;">Leave Reason  <div style="position: absolute;left: 90px;top: 0;">: ..................................................................</div></div>
                        <span style="position: absolute;top: 160px;left: 110px;color: #515151;"></span>

                        <span style="position: absolute;bottom: 95px;left: 10px;color: #515151;z-index: 2;">Jakarta   31-08-2023</span>
                        <span style="position: absolute;bottom: 90px;left: 10px;z-index: 2">........, ...................</span>
                        <div style="position: absolute;bottom: -90px;left:-120px;border: none;">
                            <div style="transform: scale(20%);border: none;">
                                <img :src="image" />
                            </div>
                        </div>
                        <span style="position: absolute;bottom: 35px;left: 10px;color: #515151;">Alex Martin</span>
                        <span style="position: absolute;bottom: 30px;left: 10px">.............................</span>
                        <span style="position: absolute;bottom: 10px;left: 10px">Project Manager</span>

                        <span style="position: absolute;bottom: 90px;right: 10px;z-index: 2">Employee Name,</span>
                        <img style="position: absolute;bottom: 50px;right:10px;width: 100px;" src="/assets/images/signature/default.png" alt="">
                        <span style="position: absolute;bottom: 35px;right: 10px;color: #515151;">John Doe</span>
                        <span style="position: absolute;bottom: 30px;right: 10px">.............................</span>
                    </div>

                    <div class="download-button">
                        <div class="download-icon">
                            <i class="fa-solid fa-download"></i>
                        </div>
                        <a style="position: absolute;width: 100%;display: flex;flex-wrap: nowrap;margin-left: 40px;text-decoration: none;" href="">Surat-sakit.pdf</a>
                    </div>

                    <label>Supervisor Signature</label>
                    
                    <div class="flex-row">
                        <div class="source">
                            <vue-drawing-canvas
                            ref="VueCanvasDrawing"
                            v-model:image="image"
                            :width="330"
                            :height="300"
                            :stroke-type="strokeType"
                            :line-cap="lineCap"
                            :line-join="lineJoin"
                            :fill-shape="fillShape"
                            :eraser="eraser"
                            :lineWidth="line"
                            :color="color"
                            :background-color="backgroundColor"
                            :background-image="backgroundImage"
                            :watermark="watermark"
                            :initial-image="initialImage"
                            saveAs="png"
                            :styles="{
                                border: 'solid 1px #000',
                            }"
                            :lock="disabled"
                            @mousemove="getCoordinate($event)"
                            :additional-images="additionalImages"
                            />
                            
                            <div class="button-container">
                                <button type="button" class="draw-btn" @click.prevent="disabled = !disabled">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16">
                                        <path v-if="!disabled" d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
                                        <path v-else d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
                                    </svg>
                                    <span v-if="!disabled">Save</span>
                                    <span v-else>Edit</span>
                                </button>
                                <button type="button" class="draw-btn" @click.prevent="$refs.VueCanvasDrawing.undo()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                                        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                                    </svg>
                                    Undo
                                </button>
                                <button type="button" class="draw-btn" @click.prevent="$refs.VueCanvasDrawing.redo()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                    </svg>
                                    Redo
                                </button>
                                <button type="button" class="draw-btn" @click.prevent="$refs.VueCanvasDrawing.reset()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                    </svg>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <button class="request-submit">
                        <span style="margin-top: 15px">Approve</span>
                    </button>
                    <button class="request-reject">Reject</button>
            </div>
        </div>
    </div>
</template>

<script>
import VueDrawingCanvas from 'vue-drawing-canvas'
import Navbar from '@/components/Navbar.vue';
import LeaveApprovalLoader from '@/components/Loader/LeaveApprovalLoader.vue';
export default {
    name: 'ApproveLeaveRequest',
    components: {
        Navbar, 
        LeaveApprovalLoader,
        VueDrawingCanvas,
    },
    data(){
        return{
            showModal: false,
            duration: 'Full Day',
            loading: true,
            initialImage: [
                {
                type: "dash",
                from: {
                    x: 262,
                    y: 154,
                },
                coordinates: [],
                color: "#000000",
                width: 5,
                fill: false,
                },
            ],
            x: 0,
            y: 0,
            image: "",
            eraser: false,
            disabled: false,
            fillShape: false,
            line: 4,
            color: "#000000",
            strokeType: "dash",
            lineCap: "round",
            lineJoin: "round",
            backgroundColor: "#FFFFFF",
            backgroundImage: null,
            watermark: null,
            additionalImages: [],
        }
    },
    created(){
        setTimeout(() => {this.loading = false}, 2000);
    },
    mounted(){
        if ("vue-drawing-canvas" in window.localStorage) {
            this.initialImage = JSON.parse(
                window.localStorage.getItem("vue-drawing-canvas")
            );
        }
    },
    methods: {
        async setImage(event) {
            let URL = window.URL;
            this.backgroundImage = URL.createObjectURL(event.target.files[0]);
            await this.$refs.VueCanvasDrawing.redraw();
        },
        async setWatermarkImage(event) {
            let URL = window.URL;
            this.watermark = {
                type: "Image",
                source: URL.createObjectURL(event.target.files[0]),
                x: 0,
                y: 0,
                imageStyle: {
                width: 600,
                height: 400,
                },
            };

            await this.$refs.VueCanvasDrawing.redraw();
        },
        getCoordinate(event) {
            let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
            this.x = coordinates.x;
            this.y = coordinates.y;
        },
        getStrokes() {
            window.localStorage.setItem(
                "vue-drawing-canvas",
                JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
            );
            alert(
                "Signatures saved, reload your browser to see the canvas with previously saved image"
            );
        },
        removeSavedStrokes() {
            window.localStorage.removeItem("vue-drawing-canvas");
            alert("Signatures cleared from local storage");
        },
    },
}
</script>

<style>
.create-request{
    position: relative;
    top: 10px;
    left: 0;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-request .request-card{
    position: relative;
    width: 90%;
    height: auto;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 10px;
    border: 1px solid var(--border);
    background: var(--white);
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px
}

.create-request .request-card label{
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    font-size: 10pt;

}

.create-request .request-card .request-title{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: var(--bluecolor);
}

.create-request .request-card select{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid var(--border);
    background: var(--white);
    font-size: 11pt;
    padding: 10px;
}

.create-request .request-card textarea{
    width: 94%;
    border-radius: 10px;
    border: 1px solid var(--border);
    background: var(--white);
    font-size: 11pt;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.create-request .request-card .card-input{
    width: 94%;
    height: 25px;
    border-radius: 10px;
    border: 1px solid var(--border);
    background: var(--white);
    font-size: 11pt;padding: 10px
}

.create-request .request-card .request-submit{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    background: var(--bluecolor);
    color: var(--white);
    border: none;
    font-size: 12pt;
    font-family: Arial, Helvetica, sans-serif;
}

.create-request .request-card .request-reject{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 5px;
    background: var(--melawai);
    color: var(--white);
    border: none;
    font-size: 12pt;
    font-family: Arial, Helvetica, sans-serif;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px
}

.button-container > * {
    margin-top: 15px;
    margin-right: 10px;
}

.button-container .draw-btn{
    width: 25%;
    height: 40px;
    background: var(--backcolor);
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
}

.download-button{
    position: relative;
    width: 200px;
    height: 30px;
    border: 1px solid var(--bluecolor);
    display: flex;
    flex-direction: row;
    color: #fff;
    overflow-x: hidden;
    align-items: center;
    cursor: pointer;
}

.download-button .download-icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    background: var(--bluecolor);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>