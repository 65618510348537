<template>
    <div class="home-loader">
        <div class="home-card">
            <div class="shimmer-wrapper" style="width: 95%;height: 250px">
                <div class="profile-bar mt-10">
                    <div style="width: 60px;height: 60px;margin-top: 0" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate"></div>
                    <div style="width: 60%;display: flex;flex-direction: column;margin-top: 5px">
                        <div class="shimmer-line shimmer-line-br shimmer-line-80 shimmer-animate mt-10"></div>
                        <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                    </div>
                    <div style="margin-top: 10px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
                </div>
                
                <div class="navigation-bar">
                    <div style="margin: 0;width: 48%" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-50"></div>
                    <div style="margin: 0;width: 48%" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-30 h-50"></div>
                    <div style="margin: 0;width: 48%;margin-top: 12px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-30 h-50"></div>
                    <div style="margin: 0;width: 48%;margin-top: 12px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-30 h-50"></div>
                </div>
            </div>
        </div>

        <div class="report-wrapper">
            <div style="position: absolute;top: 5px;left: 10px;width: 80px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="position: absolute;top: 5px;right: 10px;width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="position: relative;width: 20%;height: 60%;margin: 0;margin-top: 35px;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-50"></div>
            <div style="position: relative;width: 20%;height: 60%;margin: 0;margin-top: 35px;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-50"></div>
            <div style="position: relative;width: 20%;height: 60%;margin: 0;margin-top: 35px;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-50"></div>
            <div style="position: relative;width: 20%;height: 60%;margin: 0;margin-top: 35px;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-50"></div>
        </div>
        <div style="margin: 20px 10px;margin-top:150px;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
        <div style="width: 100%;display: flex;justify-content: center;margin-top: 30%">
            <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
        </div>        
    </div>
</template>

<script>
export default {
    name: 'HomeLoader'
}
</script>

<style>
.home-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    background: #fff;
    padding: 0;
    z-index: 1000;
    overflow: hidden;
}

.home-loader .home-card{
    position: relative;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    background: #929292;
}

.home-loader .home-card .profile-bar{
    position: relative;
    width: 99%;
    display: flex; 
    flex-direction: row;
    gap: 20px;
    background: #b9b9b9;
    padding: 5px;
    border-radius: 10px;
    margin-top: 5px;
}

.home-loader .home-card .navigation-bar{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 30px;
    margin-left: 5px
}

.home-loader .report-wrapper{
    position: absolute;
    top: 280px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 94%;
    height: 110px;
    margin: 0 auto;
    border: 1px solid var(--border);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #b9b9b9;
    gap: 5px;
}
</style>