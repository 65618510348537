<template>
    <login-loader v-show="loading"></login-loader>
    <div v-show="!loading" class="page-wrapper">
        <img class="login-bg" :src="selectedImage" alt="">
        <div class="login-form">
            <p class="login-form-title">Hey, Login Now </p>

            <label>
                <input required="" placeholder="" type="email" class="input" v-model="nik" id="nik" @keypress.enter="login">
                <span id="label-nik">NIK</span>
                <div class="error-message">{{ errorNik }}</div>
            </label> 
                
            <label>
                <input required="" placeholder="" type="password" class="input" v-model="password" id="password" @keypress.enter="login">
                <span id="label-password">Password</span>
                <div class="show-password" @click="showPass" id="show-password">
                    <i v-show="!showPassword" class="fa-solid fa-eye-slash" id="password-eyes"></i>
                    <i v-show="showPassword" class="fa-solid fa-eye"></i>
                </div>
                <div class="error-message">{{ errorPass }}</div>
            </label>

            <div style="display: flex;flex-direction: row;align-items: center;font-family: Arial, Helvetica, sans-serif;">
              <span>Lupa Password ? <router-link :to="{name: 'forgot'}" style="margin-left: 10px;text-decoration: none;">Reset</router-link></span>
            </div>
            <button class="login-form-submit" @click="login">
              <span v-if="!is_proccess">Login</span>

              <div v-if="is_proccess" class="spinner center">
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
              </div>
            </button>

            <span class="login-bottom-text">
                <span class="copyright">©</span> 
                  ESS SARI TIRTA INDONESIA
                <span class="version">Beta</span>
            </span>
        </div>
    </div>
    
    <alert v-if="showAlert" 
          :message="message" 
          :status="status" 
          :type="types"
          :action="action"
          :data="data"
          @clicked="onClosedAlert">
    </alert>
</template>

<script>
import LoginLoader from '@/components/Loader/LoginLoader.vue';
import Alert from '@/components/Alert.vue';

export default {
    name: 'LoginView',
    components: {
      LoginLoader,
      Alert,
    },
    data(){
      return {
          action: null,
          data: null,
          nik: '',
          password: '',
          errorNik: null,
          errorPass: null,
          loading: true,
          is_proccess: false,
          showPassword: false,
          showAlert: false,
          message: null,
          status: false,
          types: null,
          token: null,
          images: [
            '/assets/images/bg-1.png',
            '/assets/images/bg-2.png',
            '/assets/images/bg-3.png',
            '/assets/images/bg-4.png',
            '/assets/images/bg-5.png',
            '/assets/images/bg-6.png',
            '/assets/images/bg-7.png',
            '/assets/images/bg-8.png',
          ],
          selectedImage: null,
      }
    },
    watch: {
        selectedImage: function() {
          var root = document.documentElement;
          switch (this.selectedImage) {
            case "/assets/images/bg-1.png":
                root.style.setProperty("--logincolor", "#2a85b2");
                root.style.setProperty("--loginhover", "#1b5c7c");
                localStorage.setItem("basecolor","#2a85b2");
                localStorage.setItem("basehover", "#1b5c7c");
                break;
            case "/assets/images/bg-2.png":
                root.style.setProperty("--logincolor", "#f5e087");
                root.style.setProperty("--loginhover", "#d3b15f");
                localStorage.setItem("basecolor","#f5e087");
                localStorage.setItem("basehover", "#d3b15f");
                break;
            case "/assets/images/bg-3.png":
                root.style.setProperty("--logincolor", "#9be3bd");
                root.style.setProperty("--loginhover", "#82caaa");
                localStorage.setItem("basecolor","#9be3bd");
                localStorage.setItem("basehover", "#82caaa");
                break;
            case "/assets/images/bg-4.png":
                root.style.setProperty("--logincolor", "#dab2b2");
                root.style.setProperty("--loginhover", "#c99797");  
                localStorage.setItem("basecolor","#dab2b2");
                localStorage.setItem("basehover", "#c99797"); 
                break;
            case "/assets/images/bg-5.png":
                root.style.setProperty("--logincolor", "#ba99e4");
                root.style.setProperty("--loginhover", "#9374ae");
                localStorage.setItem("basecolor","#ba99e4");
                localStorage.setItem("basehover", "#9374ae");
                break;
            case "/assets/images/bg-6.png":
                root.style.setProperty("--logincolor", "#e3be9a");
                root.style.setProperty("--loginhover", "#d0a87f");
                localStorage.setItem("basecolor","#e3be9a");
                localStorage.setItem("basehover", "#d0a87f");
                break;
            case "/assets/images/bg-7.png":
                root.style.setProperty("--logincolor", "#4e9c87");
                root.style.setProperty("--loginhover", "#258672");
                localStorage.setItem("basecolor","#4e9c87");
                localStorage.setItem("basehover", "#258672");
                break;
            case "/assets/images/bg-8.png":
                root.style.setProperty("--logincolor", "#ef5656");
                root.style.setProperty("--loginhover", "#c53631");
                localStorage.setItem("basecolor","#ef5656");
                localStorage.setItem("basehover", "#c53631");
            }
        },
        nik: function() {
            this.checkFocus('nik', this.nik);
        },
        password: function() {
            this.checkFocus('password', this.password);
        }
    },
    created() {
      this.selectedImage = this.randomItem(this.images)
      setTimeout(() => {this.loading = false}, 1000);
    },
    methods: {
      showPass(){
          const eyes = document.getElementById("show-password");
          if(this.showPassword === false) {
              this.showPassword = true;
              document.getElementById("password").type = 'text';
              eyes.style.color = "var(--logincolor)"
          } else {
              this.showPassword = false;
              document.getElementById("password").type = 'password';
              eyes.style.color = "var(--gray)"
          }
      },
      checkFocus(id, data){
        if(data !== '') {
          document.getElementById(id).focus();
          const label = document.getElementById("label-" + id);
          label.style.fontFamily = 'Arial, Helvetica, sans-serif';
          label.style.top = '30px';
          label.style.fontSize = '8pt';
          label.style.fontWeight = '600';
        } else { 
          document.getElementById(id).blur()
          const label = document.getElementById("label-" + id);
          label.style.fontFamily = 'Arial, Helvetica, sans-serif';
          label.style.top = '15px';
          label.style.fontSize = '11pt';
          label.style.fontWeight = '400';
        }
      },
      randomItem (items) {
        return items[Math.floor(Math.random()*items.length)];
      },
      login(){
        if(!this.nik) this.errorNik = "Field NIK harus diisi!";
        if(!this.password) this.errorPass = "Field password harus diisi";
        if(!this.nik || !this.password) return;

        this.is_proccess = true

        const data = {
          nik: parseInt(this.nik),
          password: this.password,
        }

        if(this.nik && this.password){
          this.$store.dispatch("LOGIN", data)
          .then(() => {
              this.is_proccess = false
              this.$router.push({ name : 'home'});
          }).catch((error) => {
              if(error.response.status == 406){
                this.token = error.response.data.data
                this.$router.push({name: 'reset', params: {token: this.token}})
                return;
              }

              if(error.response.data.message == 'Already logged in, Switch to this tab?'){
                  this.action = 'relogin',
                  this.data = {
                    nik: this.nik,
                    password: this.password,
                  }
              }

              this.is_proccess = false
              this.message = error.response.data.message
              this.status = false
              this.showAlert = true
          })  
        }

        setTimeout(() => {
            this.errorNik = null;
            this.errorPass = null;
        }, 5000);
      },
      onClosedAlert(value){this.showAlert = value},
    },    
}
</script>

<style>
.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: 10px;
}

.spinner.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {background-color: #fff;}
  100% {background-color: transparent;}
}
</style>