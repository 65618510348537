<template>
    <div class="login-loader">
        <div class="shimmer-circle shimmer-circle-sm shimmer-animate bg-login" style="width: 350px;height: 350px;"></div>
        <div class="login-form">
            <div class="shimmer-wrapper" style="width: 100%;">
                <div style="display: flex; flex-direction: columns;gap: 10px" class="mt-40">
                    <div style="width: 25px;height: 25px;" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
                    <div style="margin-top: 11px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
                </div>
                
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate mt-60 h-40"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate mt-20 h-40"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate mt-10"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate mt-50 h-50"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginLoader',
}
</script>

<style>
.login-loader{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    background: #fff;
    z-index: 1000;
    overflow: hidden;
}

.login-loader .bg-login{
    position: absolute;
    top: 8%;
    width: 340px;
    height: 340px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.login-loader .login-form{
    position: relative;
    width: 88%;
    height: 350px;
    border-radius: 5px;
    border: 1.5px solid #e0e0e0;
    margin: 0 auto;
    margin-top: 25px;
    margin-left: 0;
    padding: 15px;
    background: #fff;
    box-shadow: 0 3px 5px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
</style>